import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results 6-8 weeks',
}

const questions: Array<Question> = [
  {
    question: 'How does Brow Lamination work?',
    answer:
      'The technician applies a brow straightening cream to soften and tame ' +
      'straggly brows into a neat shape. The brows are then brushed upwards to ' +
      'give the illusion of fullness, and a setting lotion is applied to keep ' +
      'them in shape. Lasts 6-8 weeks.',
  },
  {
    question: 'Brow Lamination vs Microblading',
    answer:
      'Laminated brows are just as impressive as microbladed brows, ' +
      'but the two treatments are completely different. ' +
      'Microblading is much more invasive because it is a semi-permanent tattoo involving needles.' +
      ' Tattooed brows give the illusion of fullness, whereas laminating straightens ' +
      'out unruly brows for a smooth, defined shape. Although laminated brows are temporary,' +
      ' they are a much more affordable way to get perfectly groomed brows.',
  },
]

const Eyebrows: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Brow Lamination and Tint - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="Brow Lamination and Tint" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                What is your main eyebrow concern? Unruly, patchy brows? Short
                brows? Gaps from overplucking or waxing? If you are nervous
                about needles, brow lamination and a tint could be the low-key
                alternative to microblading.
              </p>
              <p>
                Women in Russia and the Far East have been straightening their
                brows for years. Think of it as a lash lift for your brows:
                expect fuller, more defined brows in under an hour without
                needles or pain.
              </p>
              <p>
                At Ada Aesthetics, we understand the power of expertly groomed
                brows and specialise in coaxing unruly brows into shape. Get
                ready for your Instaworthy eyebrow arch!
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default Eyebrows

export const pageQuery = graphql`
  query Eyebrows {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
